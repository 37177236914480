import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import sara from '../../imgs/sara.png'
import stella from '../../imgs/stella.png'
import review from '../../imgs/review.png'
import check from '../../imgs/check.png'
import chiama from '../../imgs/chiama.png'
import comp2 from '../../imgs/comp2.png';
import { FaArrowRight, FaGraduationCap } from 'react-icons/fa'
import './parlaConSara.css'
import { useSearch } from '../../context/SearchContext'
import axios from 'axios'

const ParlaConSara = () => {
  const { lastName, firstName, email, phone } = useSearch();
    window.scrollTo(0, 0);
  const navigate = useNavigate()
  const [giorni, setGiorni] = useState([]);
  const [daySelected, setDaySelected] = useState();
  const [ora, setOra] = useState('09');
  const [minuto, setMinuto] = useState('00'); 
  const oggi = new Date();

  const setAppData = (data, orario) => {
    const partiData = data.split("/");

    const giorno = parseInt(partiData[0], 10);
    const mese = parseInt(partiData[1], 10);
    const anno = parseInt(partiData[2], 10);

    const annoFormattato = anno.toString().slice(-2);
    const meseFormattato = mese.toString().padStart(2, '0');
    const giornoFormattato = giorno.toString().padStart(2, '0');

    const dataOraFinale = `${annoFormattato}-${meseFormattato}-${giornoFormattato} ${orario}`;
    return dataOraFinale;
  }
    const isMobile = () => {
        return window.innerWidth <= 768;
    };
    const nomeGiorno = (d) => {
        const giorniSettimana = ['DOM', 'LUN', 'MAR', 'MER', 'GIO', 'VEN', 'SAB'];
        return giorniSettimana[d.getDay()];
      };
    useEffect(() => {
        const prossimiGiorni = [];
        const formatter = new Intl.DateTimeFormat('it-IT', { day: 'numeric', month: 'numeric', year: 'numeric' });
        for (let i = 0; i < 7; i++) {
          const dataGiorno = new Date(oggi);
          dataGiorno.setDate(oggi.getDate() + i);
          const dataFormattata = formatter.format(dataGiorno); // Formatta la data GG/MM/YYYY

          prossimiGiorni.push({
            nome: nomeGiorno(dataGiorno),
            numero: dataGiorno.getDate(),
            dataFormattata: dataFormattata,
            oggi: i === 0,
            weekend: dataGiorno.getDay() === 6 || dataGiorno.getDay() === 0
          });
        }
        setDaySelected(prossimiGiorni[0])
        setGiorni(prossimiGiorni);
      }, []);
      console.log(daySelected)
      const handleSchedule = async () => {
        const orario = `${ora}:${minuto}`;
        const data = daySelected.dataFormattata;
        const appData = setAppData(data, orario)
        const leadData = {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone: phone,
          appointment_date: appData,
          provenienzaApp: "comparatore",
        };
        console.log(leadData)
        try {
          //https://servercpchatbot.up.railway.app/api/setAppComparacorsi
          //http://localhost:8000/api/setAppComparacorsi
          const response = await axios.post('https://servercpchatbot.up.railway.app/api/setAppComparacorsi', leadData);
          console.log(response.data);
          navigate(`/parla-con-sara/thanks?orario=${orario}&giorno=${data}`)
        } catch (error) {
          console.error(error)
        }
      }

      const whatsClick = async () => {
        const leadData = {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone: phone,
          whatsClick: 'si',
        };
        console.log(leadData)
        window.open(`https://wa.me/393513583765`, '_blank')
      try {
          //https://servercpchatbot.up.railway.app/api/setWhatsClick
          //http://localhost:8000/api/setWhatsClick
          const response = await axios.post('https://servercpchatbot.up.railway.app/api/setWhatsClick', leadData);
          console.log(response.data);
      } catch (error) {
          console.error(error)
        }
      }
  return (
    <div className='risultati'>
      <div>
        <div className='comparatore-top not-sticky'>
            <div style={isMobile() ? {width: '100%'} : null}>
                {!isMobile() && <FaGraduationCap />}
                <h2 style={isMobile() ? {fontSize: '16px', fontWeight: '400', width: '90%'} : null}><b>Parla con Sara</b> gratuitamente per ricevere tutte le informazioni di cui hai bisogno.</h2>
            </div>
            <div>
                <img alt='sequenza comparatore' src={comp2} />
            </div>
        </div>
        <div className='sara-top'>
          <div>
            <img alt='sara orientatrice' src={sara} />
          </div>
          <div>
            <p>Sara</p>
            <p><b>Orientatrice</b> <img alt='review' src={stella} /></p>
            <hr />
            <img alt='review' src={review} />
            <p>Su 178 recensioni</p>
          </div>
        </div>
        <div className='sara-middle'>
            <p>Orientatrice universitaria dal 2016, ho guidato oltre 3.000 persone verso l'università ideale, personalizzando ogni percorso accademico.</p>
            <hr />
            <h2>Prenota una call</h2>
            <h4>SELEZIONA UNA DATA</h4>
            <div className='giorni-container'>
                {giorni.map((giorno, index) => (
                <div onClick={giorno.weekend ? null : () => setDaySelected(giorno)} key={index} className={`${daySelected?.nome === giorno.nome  ? 'oggi' : ''} ${giorno.weekend ? 'weekend' : ''}`}>
                    <div>{giorno.nome}</div>
                    <div>{giorno.numero}</div>
                </div>
                ))}
            </div>
            <div className='hour-container'>
              <h4>SELEZIONA UN ORARIO</h4>
              <div className='select-orario'>
                <select value={ora} onChange={(e) => setOra(e.target.value)}>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                </select>
                <p>:</p>
                <select value={minuto} onChange={(e) => setMinuto(e.target.value)}>
                    <option value="00">00</option>
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="45">45</option>
                </select>
              </div>
              <div>
                <p><img alt='chiamata' src={chiama} /> Durata 15 minuti</p>
                <p><img alt='check' src={check} /> 100% gratuita</p>
              </div>
            </div>
            <div className='cta-sara'>
                <button onClick={handleSchedule}>Prenota la call <FaArrowRight /></button>
                <hr />
                <p>Oppure</p>
                <button onClick={whatsClick}>Scrivile su Whatsapp</button>
            </div>
        </div>
      </div>
    </div>
  )
}

export default ParlaConSara